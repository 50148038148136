import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

export default function InterviewSkills() {
  return (
    <>
      <Helmet>
        <title>
          DSA Interview Training Bootcamp - By Top Interview Prepper | Public
          Speaking Academy
        </title>
        <meta
          name="description"
          content="Led by Darren Tay, our public speaking training course coaches you effective communication and presentation skills in group. Contact us at 6443 4049 today!"
        ></meta>
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>DSA INTERVIEW TRAINING BOOTCAMP</div>
            <div>
              Home / Courses & Programs / Public Speaking for Kids / DSA
              Interview Training Bootcamp
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-x-10">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-3xl font-bold text-primary uppercase">
                MARCH SCHOOL HOLIDAY INTERVIEW SKILLS PROGRAMME FOR CHILDREN
              </h1>
              <h2 class="font-primary text-xl lg:text-2xl font-bold text-primary uppercase py-5">
                A 2-HALF-DAY INTENSIVE, INTERPERSONAL COMMUNICATION HOLIDAY CAMP
                FOR KIDS
              </h2>
              <div>
                <img
                  src="/images/Kids intensive - featured pic-min.png"
                  alt="MARCH SCHOOL HOLIDAY INTERVIEW SKILLS PROGRAMME FOR CHILDREN"
                />
                <h2 class="font-primary text-xl lg:text-2xl font-bold text-primary pt-5">
                  Have Your Child Ace His/ Her Next High-Stakes Interview
                  Skilfully & Confidently!
                </h2>
                <p class="font-text text-lg py-2">
                  Preparing for school admission interviews, scholarship/
                  internship interviews, or CCA leadership prefectorial board
                  elections can be nerve-wracking, especially when our young
                  ones almost do not getting any interview skills training from
                  school at all. Finding the right guidance to help your child
                  be outstanding amongst the competition is equally challenging.
                  A long-term commitment might not suit everyone, but we have
                  the perfect solution – the Interview Skills Mastery (ISM)!
                </p>
                <p class="font-text text-lg pt-2 pb-5">
                  Whether your child is gearing up for their first big interview
                  (e.g. getting into their dream school) or needs a boost in
                  skills & confidence to ace their subsequent ones, our
                  Interview Skills Mastery (ISM) is the answer. Running since
                  2016, this signature holiday camp by the Public Speaking
                  Academy equips learners with the communication tools and
                  strategies to stand out in interviews and beyond.
                </p>
                <img
                  src="/images/interview-skills-1.png"
                  alt="image of how interview skills training can lead to the opening of doors of opportunities"
                />
                <p class="pb-1 pt-5 text-xl font-bold">
                  Introducing the Interview Skills Mastery (ISM) Programme
                </p>
                <p class="font-text text-lg py-1">
                  (A tailored only-in March holiday Communication Skills
                  workshop for children ages 9–12 & 13–18)
                </p>
                <p class="font-text text-lg py-1 pb-5">
                  The Interview Skills Mastery program is a 2-half-day intensive
                  workshop on interpersonal communication, self-pitching and
                  interview skills, designed to prepare young learners for
                  success in high-stakes interview situations. From formulating
                  compelling responses swiftly, to mastering effective &
                  impressive body language, we focus on 7 core competencies
                  tackling interview questions such as Hypothetical questions,
                  School and interest-based questions, personal disclosure
                  questions, body language techniques and more!
                </p>
                <p class="font-text text-lg py-1 pb-5">
                  Led by Darren Tay, World Champion of Public Speaking 2016, and
                  our Senior Trainers, this programme offers a deep dive into
                  essential skills that will set your child apart.
                </p>

                <img
                  src="/images/interview-skills-2.jpg"
                  alt="image of master trainer darren tay sharing a secret about our interview skills training program"
                />
                <p class="pb-5 font-text text-lg pt-5">
                  We will let you in on a secret – the main trainer of ISM,
                  Darren, has been a highly sought after 1-to-1 interview skills
                  trainer and prepper since 2016; it has always been private
                  training sessions where Darren imparts the interview tips and
                  strategies and confidence-building advantages to these
                  students. This is the first program specifically designed and
                  conducted by Darren to let more students benefit from the
                  interview skills mastery secrets!
                </p>

                <p class="pb-5 font-text text-lg">
                  There will be plenty of interview opportunities or
                  “challenges” (depends on how you perceive it) down the road
                  for your child. This is a preparatory program that, we
                  believe, every student should attend at least once in their
                  lifetime (so that they will be better prepared for all
                  subsequent interviews in their future ahead)!
                </p>

                <p class="pb-5 font-text text-lg">
                  Our aim is to enable graduates of the Interview Skills Mastery
                  (ISM) program to walk away from every subsequent interview
                  (whether it’s for DSA, internship, scholarship, CCA leadership
                  committee, prefectorial board, and etc.) feeling and knowing
                  that they have:
                </p>

                <img
                  src="/images/interview-skills-3.png"
                  alt="image of a person walking out of an interview session feeling confident"
                />
                <hr />

                <h2 class="font-primary text-xl lg:text-2xl font-bold text-primary py-5">
                  What to Expect in the “ISM” Holiday Camp
                </h2>
                <h3 class="font-primary text-xl lg:text-xl font-bold text-primary">
                  Catch a glimpse of How Your Child is Going to be Guided Along
                  the insider tips!
                </h3>

                <div className="py-5">
                  <div className="aspect-video">
                    <iframe
                      class="w-full h-full"
                      src="https://www.youtube.com/embed/I59TOzxJBys"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="mx-auto">
                <h2 class="font-primary text-3xl font-bold text-primary text-center py-5">
                  The Interview Skills Training Camp Your Child Needs This March
                  Holiday Before DSA season!
                </h2>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Theory/ Technique Building (taught by Mr Darren Tay)
                  </h3>
                  <p className="text-lg text-center ">
                    Learners receive proprietary materials and are guided
                    step-by-step through proven communication and interview
                    techniques. Our professional trainers use engaging methods
                    like quizzes, group discussions, and interactive challenges
                    to keep sessions dynamic.
                  </p>
                </div>
                <div className="my-2">
                  <svg
                    className="mx-auto text-center"
                    width="24"
                    height="58"
                    viewBox="0 0 24 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9393 57.0607C11.5251 57.6464 12.4749 57.6464 13.0607 57.0607L22.6066 47.5147C23.1924 46.9289 23.1924 45.9792 22.6066 45.3934C22.0208 44.8076 21.0711 44.8076 20.4853 45.3934L12 53.8787L3.51472 45.3934C2.92893 44.8076 1.97918 44.8076 1.3934 45.3934C0.807609 45.9792 0.807609 46.9289 1.3934 47.5147L10.9393 57.0607ZM10.5 -6.55671e-08L10.5 56L13.5 56L13.5 6.55671e-08L10.5 -6.55671e-08Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Breakout Practices (facilitated by 1-2 facilitators who are
                    Senior Trainers at PSA)
                  </h3>
                  <p className="text-lg text-center ">
                    Participants apply what they’ve learned in smaller, focused
                    breakout sessions. These allow young communicators to refine
                    their techniques in a safe and supportive environment. The
                    facilitators offer evaluation to individual students so that
                    they may improve on the application of the techniques.
                  </p>
                </div>
                <div className="my-2">
                  <svg
                    className="mx-auto text-center"
                    width="24"
                    height="58"
                    viewBox="0 0 24 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9393 57.0607C11.5251 57.6464 12.4749 57.6464 13.0607 57.0607L22.6066 47.5147C23.1924 46.9289 23.1924 45.9792 22.6066 45.3934C22.0208 44.8076 21.0711 44.8076 20.4853 45.3934L12 53.8787L3.51472 45.3934C2.92893 44.8076 1.97918 44.8076 1.3934 45.3934C0.807609 45.9792 0.807609 46.9289 1.3934 47.5147L10.9393 57.0607ZM10.5 -6.55671e-08L10.5 56L13.5 56L13.5 6.55671e-08L10.5 -6.55671e-08Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Mock Interview Stations
                  </h3>
                  <p className="text-lg text-center ">
                    On Day 2, the facilitators will have mock interview stations
                    as part of the breakout practice so that students have a
                    feel of the flow of an interview.
                  </p>
                </div>
                <div>
                  <p class="py-8 font-text text-lg">
                    The ISM March holiday camp’s syllabus focuses on 7 core
                    competencies of interpersonal communication and pitching
                    skills that apply to our young learners’ day-to-day in
                    school, at home, and even in the future. From quick
                    response-crafting techniques to displaying positive body
                    language that we will be imparting in this holiday workshop,
                    your child will explore the interview techniques and tips
                    with the guidance of Singapore’s first Public Speaking World
                    Champion and our experienced senior trainers:
                  </p>
                </div>

                <div className="py-5">
                  <h2 class="text-center text-xl font-bold pb-1">
                    The Programme Syllabus
                  </h2>
                  <h2 class="text-center text-2xl font-bold uppercase text-primary pb-1">
                    The 7 Core Competencies that will be covered in the 2
                    Half-Day ISM training are:
                  </h2>

                  <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-5 my-14">
                    <div className="w-full">
                      <div className="px-5 py-5 border border-dashed border-slate-200 bg-slate-100 rounded-2xl">
                        <div className="mt-5">
                          <p className="px-2 py-2 my-3 font-bold text-center rounded-md text-md bg-slate-300 font-primary">
                            1. How to tackle personal disclosure questions and
                            personal experience questions
                          </p>

                          <p className="px-2 py-2 my-3 font-bold text-center rounded-md text-md bg-slate-300 font-primary">
                            2. How to tackle Hypothetical interview questions
                          </p>

                          <p className="px-2 py-2 my-3 font-bold text-center rounded-md text-md bg-slate-300 font-primary">
                            3. ⁠How to tackle School and interest-based
                            interview questions
                          </p>

                          <p className="px-2 py-2 my-3 font-bold text-center rounded-md text-md bg-slate-300 font-primary">
                            4. How to tackle Forecast-type interview questions
                          </p>

                          <p className="px-2 py-2 my-3 font-bold text-center rounded-md text-md bg-slate-300 font-primary">
                            5. How to tackle Normative/ opinion-based interview
                            questions
                          </p>

                          <p className="px-2 py-2 my-3 font-bold text-center rounded-md text-md bg-slate-300 font-primary">
                            6. ⁠Body language techniques when going for an
                            interview
                          </p>

                          <p className="px-2 py-2 my-3 font-bold text-center rounded-md text-md bg-slate-300 font-primary">
                            7. ⁠Group interview techniques
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section id="review">
                  <div className="w-full py-5 my-5 bg-slate-100 rounded-xl">
                    <div className="pt-10 pb-5">
                      <h2 className="text-xl font-bold text-center font-primary">
                        What Our Students Have To Say About How Training Is Like
                        With Mr Darren:
                      </h2>
                    </div>
                    <div className="flex flex-col px-5 md:flex-row">
                      <div className="px-5 pb-10 md:w-1/2">
                        <div className="aspect-video">
                          <iframe
                            class="w-full h-full"
                            src="https://www.youtube.com/embed/Ai5BFT2plXc"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                      <div className="px-5 pb-10 md:w-1/2">
                        <div className="aspect-video">
                          <iframe
                            class="w-full h-full"
                            src="https://www.youtube.com/embed/J1BZp26CXV4"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="block-3">
                  <div>
                    <p class="text-2xl font-bold py-2 italic">
                      ISM Kids March Holiday Camp Details - How Do I Enrol My
                      Child?
                    </p>
                    <p class="pb-5 font-text text-lg">
                      The Interview Skills Mastery programme is only conducted
                      at our SAFRA Tampines Branch (1A Tampines Street 92
                      #01-13B (SAFRA Tampines) Singapore-528882), with the
                      following schedule:
                    </p>
                  </div>
                  <div className="flex flex-row space-x-5">
                    <div className="w-1/2 py-5 rounded-lg bg-cyan-50">
                      <h2 className="text-xl font-bold text-center font-primary">
                        For 9 to 12 years old
                      </h2>
                      <h3 className="text-lg text-center font-primary">
                        Primary School Group
                      </h3>
                      <div className="py-5 text-center">
                        <p className="font-bold">
                          18 & 19 March 2025 (Tue & Wed)
                        </p>
                        <p>9.30 am to 1.00 pm on both half-days</p>
                        <p className="font-bold text-red-600">
                          Open for Registration!
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2 py-5 bg-orange-100 rounded-lg">
                      <h2 className="text-xl font-bold text-center font-primary">
                        For 13 to 18 years old
                      </h2>
                      <h3 className="text-lg text-center font-primary">
                        Sec School/Teen Group
                      </h3>
                      <div className="py-5 text-center">
                        <p className="font-bold">
                          20 & 21 March 2025 (Thur & Fri)
                        </p>
                        <p>9.30 am to 1.00 pm on both half-days</p>
                        <p className="font-bold text-red-600">
                          Open for Registration!
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class="py-5 font-text text-lg text-center font-bold">
                    (doors open for attendance from 9 to 9.30 am for both
                    sessions, with class starting strictly at 9.30 am)
                  </p>
                  <div className="w-full p-5 my-10 bg-gray-100 rounded-lg">
                    <h2 className="text-xl font-bold text-center font-primary">
                      Price Details
                    </h2>
                    <h3 className="py-3 text-2xl text-center font-text">
                      We are offering a limited seats-only rate of – S$459 nett
                    </h3>
                    <h3 className="text-lg text-center font-text">
                      We are offering a limited seats-only rate of – S$459 nett
                      (all materials, evaluation, certificates included)
                    </h3>
                  </div>
                  <div className="my-10 text-center">
                    <NavLink
                      className="px-4 py-4 text-md lg:text-xl font-bold text-white font-primary bg-btnColor rounded-xl"
                      to="https://docs.google.com/document/d/1UtjBHRaeYr8fGmPiokMPHrEQ0U_PPXiwITpG66LYLk0/edit?usp=sharing"
                    >
                      Click here to book your slot! (instructions on next page)
                    </NavLink>
                  </div>
                </section>
                <section>
                  <h2 className="my-10 text-2xl font-bold text-center">
                    Other Key Programme Details
                  </h2>

                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      Where is the class conducted?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      <b>SAFRA TAMPINES BRANCH</b>
                      <br />
                      Public Speaking Academy @ Tampines <br />
                      1A Tampines Street 92 #01-13B <br />
                      SAFRA Tampines <br />
                      Singapore-528882.
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      What is the class size?
                      <br />
                      Can I attend a trial?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      Maximum class size of 30 students. All 30 students will be
                      in our main classroom, learning the techniques and theory
                      from our Master Trainer Mr Darren Tay. Thereafter,
                      students will be split into 2 groups for the breakout
                      practices (each group facilitated by a Senior Trainer who
                      will be evaluating the students and helping them apply the
                      techniques taught). There will be 1-2 breakout sessions,
                      each on Day 1 and Day 2.
                      <br />
                      <br />
                      As this is an intensive programme with limited capacity,
                      there is no trial class. Likewise, only enrolled students
                      will be allowed to sit in for the programme to ensure a
                      constructive, conducive and safe learning environment.
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      Who are the Trainers?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      The programme will be helmed by our Principal/ World
                      Champion of Public Speaking Mr Darren Tay. He will be
                      assisted by facilitators (our Senior Trainers). The
                      facilitators will be the ones to take charge of the
                      breakout sessions and evaluation of the participants in
                      their respective breakout sessions.
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      What if My child Misses a Class or needs to leave early?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      There is no replacement for the missed session or a
                      refund/pro-rate for the session/portions missed. We
                      recommend parents/students to confirm their availability
                      for the full programme before signing up (you may instead
                      consider our regular public speaking programme).
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      I see that your June and Dec school holiday programmes
                      cover "interview skills" as one of the modules. Is it the
                      same as per your ISM?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      The "interview skills" module covered in the June and Dec
                      school holiday programmes cover some fundamentals without
                      going into greater depth (due to time constraint). The ISM
                      is much more in-depth in terms of the techniques as well
                      as the breakout practices.
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      Will your side help to prepare the DSA portfolio and teach
                      students how to write a personal statement and prepare the
                      written interview segment (where applicable)?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      The ISM only covers techniques and strategies to tackle
                      the verbal interview segment. We do not teach the written
                      interview segment nor does the ISM course teach students
                      to write personal statements.
                      <br />
                      <br />
                      Nevertheless, we have an infokit (which we give it
                      free-of-charge to all participants of ISM) which shares
                      tips and strategies to write a personal statement as well
                      as a checklist that individuals can run through when
                      preparing for their portfolio/ resume.
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      Are there any meals provided?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      As the ISM is a two half-day training (from 9.30am to
                      1pm), lunch is not provided. We do have some biscuits that
                      students can help themselves with. Students may also bring
                      their own snacks in case they are hungry or if they have
                      specific food allergies.
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      If I would like advice or more tailored help to prepare my
                      child for an interview, is there something that I can go
                      for after the ISM?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      Yes, students who complete the ISM may consider our
                      one-to-one training with Mr Darren Tay to help with
                      specific interview preparation. You may reach out to us
                      and we can send you the rates for our one-to-one training.
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-y border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      How are your classes conducted with the present COVID-19
                      situation?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      Our programmes are conducted with proper clearance from
                      the relevant Government authorities (Ministry of Education
                      and EnterpriseSG).
                      <br />
                      <br />
                      In the event of any COVID-19 measures, legislation, or any
                      directive from the Government disrupting the conduct of
                      the programme, there will be no refund of the fees paid.
                      Instead, the programme will be postponed to another
                      date/time (as per our Terms & Conditions in our
                      Registration Form).
                    </div>
                  </div>
                </section>
                <section>
                  <h2 className="my-10 text-2xl font-bold text-center">
                    Find out more about our Track Record since 2009!
                  </h2>
                  <img
                    className="my-3"
                    src="/images/image showing satisfied corporate clients of s'peak performance public speaking skills training program-min.png"
                    alt=""
                  />
                  <img
                    className="my-3 text-center"
                    src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-3-3.jpg"
                    alt=""
                  />
                </section>
                <section>
                  <div className="my-10">
                    <p class="text-lg font-bold pb-5">
                      Tailored for Real-World Success
                    </p>
                    <p class="font-text text-lg">
                      The ISM March school holiday camp’s curriculum equips
                      young learners with skills they can apply in school, at
                      home, and in their future pursuits. With a focus on
                      interview-specific techniques, such as:
                    </p>
                    <ul className="p-5 list-disc list-outside">
                      <li className="pb-5 text-lg font-text">
                        Swiftly Formulate responses to common interview
                        questions
                      </li>
                      <li className="pb-5 text-lg font-text">
                        Competently handle tough and not-so-common interview
                        questions
                      </li>
                      <li className="pb-5 text-lg font-text">
                        Communicating personal strengths and achievements
                        (self-pitching) effectively
                      </li>
                      <li className="pb-5 text-lg font-text">
                        Managing nervousness and projecting confidence
                      </li>
                      <li className="pb-5 text-lg font-text">
                        Building rapport with interviewers through active
                        listening and engaging communication
                      </li>
                    </ul>
                    <p class="font-text text-lg">
                      This programme is the perfect preparation for key moments
                      like school admission interviews, scholarship panels, and
                      leadership selection boards. Under the guidance of
                      Singapore’s first Public Speaking World Champion and our
                      experienced senior trainers, your child will gain the
                      tools to shine in any interview setting.
                    </p>
                    <p class="font-text text-lg">
                      Give your child the interview Advantage this March
                      holiday!
                    </p>
                    <div className="my-10 text-center">
                      <NavLink
                        className="px-4 py-4 text-md lg:text-xl font-bold text-white font-primary bg-btnColor rounded-xl"
                        to="https://docs.google.com/document/d/1UtjBHRaeYr8fGmPiokMPHrEQ0U_PPXiwITpG66LYLk0/edit?usp=sharing"
                      >
                        Click here to book your slot! (instructions on next
                        page)
                      </NavLink>
                    </div>
                    <p className="text-lg font-bold py-3">
                      If you have any queries before signing up, please send us
                      an email (to: contact@publicspeakingacademy.com.sg) with
                      “ISM 2025 Kids” in the email header and we will get back
                      to you on your query!
                    </p>

                    <p className="text-lg py-3">
                      Before you even consider enrolling is our comprehensive
                      DSA Interview Workshop designed to prepare students for
                      the competitive & demanding DSA interview process, here
                      are{" "}
                      <h2 className="inline">two quick DSA Interview Tips</h2>{" "}
                      for your child!
                    </p>

                    <img
                      className="my-3 text-center"
                      src="/images/image-of-the-best-posture.png"
                      alt="image of the best posture an interviewee should adopt where the chest is outwards signalling confidence and readiness to engage - dsa interview workshop tip number 1 on body language"
                    />
                    <p className="text-lg font-bold py-3">
                      Tip Number 1: Be “Poised” To Engage
                    </p>

                    <p className="text-lg py-3">
                      Many parents and students THINK that ONLY the responses
                      matter in DSA interviews. Unbeknownst to many of them,
                      first impressions are created not JUST by the veral speech
                      aspect, but ALSO the countenance and body language!
                    </p>

                    <p className="text-lg py-3">
                      Just for the approx. 30 minutes of face-to-face interview,
                      although it may be strenuous, tell your child to sit
                      upright with his/ her chest out (*visualization: imagine a
                      hook pulling the chest OUTwards), place his/ her hands on
                      the table, wear a teeth-revealing smile. These will help
                      you convey openness, welcomeness and readiness to engage
                      in the conversation. Such posture primes you to use your
                      hand gestures and sustain your eye contact. A radiant
                      expression also builds rapport and bridge the gap between
                      the student and interviewers (for most DSA interviews,
                      your child will be meeting them for the first time, and
                      you can only create a first impression once).
                    </p>

                    <img
                      className="my-3 text-center"
                      src="/images/image-of-an-interviewee.png"
                      alt="image of an interviewee rambling when he is nervous when asked a difficult question by an interviewer - dsa interview workshop tip number 2"
                    />
                    <p className="text-lg font-bold py-3">
                      Tip Number 2: When in doubt or anxious, SHIFT your focus
                      back to the speech structure!
                    </p>
                    <p class="font-text text-lg py-3">
                      For students who lack DSA interview preparation and are
                      not trained to answer the FAQs (frequently asked questions
                      that we will guide our students step-by-step) of DSA
                      interview settings, when faced with an unexpected
                      question, they always toss all composure out of the window
                      and start catastrophizing!
                    </p>
                    <p class="font-text text-lg py-3">
                      Panicking is the most painful starting point, especially
                      when you are not sure about how to answer a difficult
                      question. Instead, taking a short pause to digest, unpack
                      and evaluate the question thrown to you may give you that
                      much-needed breathing space to respond confidently. The
                      trick is to fall back on the{" "}
                      <strong>
                        P.E.E.L structure (one of the multiple impromptu speech
                        structures that we will impart to our students)
                      </strong>{" "}
                      by calmly and systematically stating your:
                    </p>
                    <ul className="p-5 list-disc list-outside">
                      <li className="pb-5 text-lg font-text">
                        <strong>Point</strong> (e.g. "Playtime is important to a
                        child's development"),
                      </li>
                      <li className="pb-5 text-lg font-text">
                        <strong>Explain </strong> ("e.g. "because it provides
                        opportunities to learn skills not found in classrooms"),
                      </li>
                      <li className="pb-5 text-lg font-text">
                        <strong>Example </strong> (e.g. "such as socializing,
                        communication, leadership skills."),
                      </li>
                      <li className="pb-5 text-lg font-text">
                        <strong>Link </strong> back (e.g. that's why I think
                        playtime is important for all-round learning).
                      </li>
                    </ul>

                    <p class="font-text text-lg py-3">
                      If you are keen to develop your child into a confident and
                      skilful interviewee/ communicator this March school
                      holidays, join us for the Interview Skills Mastery (ISM)
                      Programme (our signature DSA interview skills training
                      camp for Kids aged 9-18 – only during the March holiday)!
                      Our founder, and 2016 World Champion of Public Speaking
                      Darren Tay, looks forward to sharing his tried and tested
                      know-hows, techniques, and experiences with your
                      child/children, in his signature school holiday camp, to
                      help your child enhance his/ her self-pitching, impromptu
                      speaking abilities and boost your child/ children’s
                      interview skills & confidence!
                    </p>
                    <p className="font-text text-lg py-3">
                      If you are also looking for public speaking courses or
                      holiday workshop for yourself (adults), you may check out
                      our{" "}
                      <NavLink
                        className="font-text text-lg text-btnColor hover:underline"
                        to="/courses-and-programs"
                      >
                        public speaking courses for adults here.
                      </NavLink>
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="hidden md:inline md:w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
