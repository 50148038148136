import React, { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

const MobileMenu = ({ close }) => {
  const [animation, setAnimation] = useState(false);
  useEffect(() => {
    setAnimation(true);
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth >= 640) {
        close();
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);
  return (
    <div>
      <div className="fixed z-[99] top-0 left-0 h-screen w-full backdrop-blur-sm bg-gray-500 bg-opacity-60">
        <div className="p-5 transition-all">
          <div
            className={`w-full bg-white rounded-xl p-5 ${
              animation ? "scale-100" : "scale-95"
            }`}
          >
            <div className="flex items-center justify-between">
              <h1>Navigation</h1>
              <IoCloseOutline
                className="w-7 h-7 hover:scale-110 transition-all cursor-pointer"
                onClick={close}
              />
            </div>
            <div className="mt-3">
              <NavLink
                to="/"
                className="block py-3 text-zinc-500 border-b"
                onClick={close}
              >
                Home
              </NavLink>

              <div className="collapse">
                <input type="checkbox" className=" min-h-0" />
                <div className="collapse-title min-h-0 px-0 py-3 text-zinc-500 border-b">
                  About
                </div>
                <div className="collapse-content px-0 py-0">
                  <NavLink
                    to="/about"
                    className="block py-3 pl-5 text-zinc-500 border-b"
                    onClick={close}
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    to="/media"
                    className="block py-3 pl-5 text-zinc-500 border-b"
                    onClick={close}
                  >
                    Media Releases
                  </NavLink>
                </div>
              </div>

              <div className="collapse">
                <input type="checkbox" className=" min-h-0" />
                <div className="collapse-title min-h-0 px-0 py-3 text-zinc-500 border-b">
                  Our Faculty
                </div>
                <div className="collapse-content p-0 m-0">
                  <NavLink
                    to="/master-trainer"
                    className="block py-3 pl-5 text-zinc-500 border-b"
                    onClick={close}
                  >
                    Darren Tay - Master Trainer
                  </NavLink>
                  <NavLink
                    to="/senior-trainers-trainers"
                    className="block py-3 pl-5 text-zinc-500 border-b"
                    onClick={close}
                  >
                    Senior Trainers
                  </NavLink>
                </div>
              </div>

              <div className="collapse">
                <input type="checkbox" className=" min-h-0" />
                <div className="collapse-title min-h-0 px-0 py-3 text-zinc-500 border-b">
                  Courses & Programs
                </div>

                <div className="collapse-content p-0 m-0">
                  <div className="collapse pl-5">
                    <input type="checkbox" className=" min-h-0" />
                    <div className="collapse-title min-h-0 px-0 py-3 text-zinc-500 border-b">
                      Public Speaking for Kids
                    </div>
                    <div className="collapse-content p-0 m-0">
                      <NavLink
                        to="/group-classes-students-age-7-18"
                        className="block py-3 pl-10 text-zinc-500 border-b"
                        onClick={close}
                      >
                        Regular, Weekly Classes
                      </NavLink>
                      <NavLink
                        to="/school-holiday-public-speaking-program-for-children"
                        className="block py-3 pl-10 text-zinc-500 border-b"
                        onClick={close}
                      >
                        Holiday Camps / Masterclasses
                      </NavLink>
                      <NavLink
                        to="/interview-skills"
                        className="block py-3 pl-10 text-zinc-500 border-b"
                        onClick={close}
                      >
                        <p className="text-zinc-500">DSA Interview Training Bootcamp</p>
                        <small className="italic text-zinc-500">(*March Only)</small>
                      </NavLink>
                    </div>
                  </div>
                  <div className="collapse pl-5">
                    <input type="checkbox" className=" min-h-0" />
                    <div className="collapse-title min-h-0 px-0 py-3 text-zinc-500 border-b">
                      Public Speaking for Adults
                    </div>
                    <div className="collapse-content p-0 m-0">
                      <NavLink
                        to="/courses-and-programs/group-classes-for-adults"
                        className="block py-3 pl-10 text-zinc-500 border-b"
                        onClick={close}
                      >
                        Regular, Weekly Classes
                      </NavLink>
                      <NavLink
                        to="/presentation-skills-training-course-by-world-champion"
                        className="block py-3 pl-10 text-zinc-500 border-b"
                        onClick={close}
                      >
                        Intensive Masterclasses/Bootcamps
                      </NavLink>
                    </div>
                  </div>
                  <NavLink
                    to="/courses-and-programs/intensive-holiday-ad-hoc-public-speaking-programs"
                    className="block py-3 pl-5 text-zinc-500 border-b"
                    onClick={close}
                  >
                    Intensive, Holiday and Ad-hoc Programs
                  </NavLink>
                  <NavLink
                    to="/courses-and-programs/premium-1-to-1-public-speaking-coaching"
                    className="block py-3 pl-5 text-zinc-500 border-b"
                    onClick={close}
                  >
                    Premium 1-to-1
                  </NavLink>
                </div>
              </div>

              <div className="collapse">
                <input type="checkbox" className=" min-h-0" />
                <div className="collapse-title min-h-0 px-0 py-3 text-zinc-500 border-b">
                  Corporate/School Training
                </div>
                <div className="collapse-content p-0 m-0">
                  <NavLink
                    to="/corporate-public-speaking-training-workshop"
                    className="block py-3 pl-5 text-zinc-500 border-b"
                    onClick={close}
                  >
                    Corporate/Business Training
                  </NavLink>
                  <NavLink
                    to="/public-speaking-training-for-schools-workshop"
                    className="block py-3 pl-5 text-zinc-500 border-b"
                    onClick={close}
                  >
                    School/Enrichment Programmes
                  </NavLink>
                </div>
              </div>
              <NavLink
                to="/national-public-speaking-competition"
                className="block py-3 text-zinc-500 border-b"
                onClick={close}
              >
                Competitions
              </NavLink>
              <NavLink
                to="/category/blog"
                className="block py-3 text-zinc-500 border-b"
                onClick={close}
              >
                Blog
              </NavLink>
              <NavLink
                to="/contact"
                className="block py-3 text-zinc-500"
                onClick={close}
              >
                Contact
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
