import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <header>
      <nav className="relative mx-auto bg-white lg:bg-primary">
        <div className="flex flex-row justify-between mx-auto">
          <div className="relative flex lg:items-center w-1/2 lg:w-1/4 bg-white lg:rounded-tr-[20px] xl:rounded-none">
            <svg
              className="hidden xl:block absolute bottom-0 right-[-80px]"
              width="80"
              height="260"
              viewBox="0 0 80 260"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M-0.000112512 0H80L0 260L-0.000112512 0Z" fill="white" />
            </svg>

            <img
              className="px-3 py-3 mx-auto w-40 sm:w-[200px] md:w-[200px] lg:w-52"
              src="/images/psalogo.png"
              alt=""
            />
          </div>
          {open ? <MobileMenu close={() => setOpen(false)} /> : <></>}
          <div className="lg:hidden">
            <button
              className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
              onClick={() => {
                setOpen(true);
              }}
            >
              <span className="text-4xl material-symbols-rounded">menu</span>
            </button>
          </div>

          <div className="hidden lg:flex lg:flex-col lg:w-3/4">
            <div className="flex flex-row justify-between my-3 lg:mx-2 xl:mx-10">
              <div className="flex flex-col border-r-2 border-white lg:flex-row w-96">
                <div className="mx-3">
                  <span className="font-semibold lg:text-3xl xl:text-4xl material-symbols-rounded text-iconColor1">
                    {" "}
                    mail{" "}
                  </span>
                </div>
                <div className="mx-1">
                  <h3 className="text-sm font-bold text-white font-primary">
                    CONTACT US AT
                  </h3>
                  <p className="text-white lg:text-xs xl:text-sm font-text">
                    support@publicspeakingacademy.com.sg
                  </p>
                  <p className="text-white lg:text-xs xl:text-sm font-text">
                    Please allow for 3-5 working days for response
                  </p>
                  <p className="text-white lg:text-xs xl:text-sm font-text">
                    Call: 64434049 | WhatsApp: 86860753
                  </p>
                </div>
              </div>

              <div className="flex flex-row border-r-2 border-white w-96">
                <div className="mx-3">
                  <span className="font-semibold lg:text-3xl xl:text-4xl material-symbols-rounded text-iconColor1">
                    {" "}
                    schedule{" "}
                  </span>
                </div>
                <div className="mx-1">
                  <h3 className="text-sm font-bold text-white uppercase font-primary">
                    Working Hours
                  </h3>
                  <p className="text-white lg:text-xs xl:text-sm font-text">
                    Wed to Sun (Calls): 12 PM – 6 PM
                  </p>
                  <p className="text-white lg:text-xs xl:text-sm font-text">
                    Monday, Tuesday & PH: Closed
                  </p>
                </div>
              </div>

              <div className="flex flex-row w-96">
                <div className="mx-3">
                  <span className="font-semibold lg:text-3xl xl:text-4xl material-symbols-rounded text-iconColor1">
                    {" "}
                    calendar_today{" "}
                  </span>
                </div>
                <div className="mx-1">
                  <h3 className="text-sm font-bold text-white uppercase font-primary">
                    WALK-INS (BY APPOINTMENT)
                  </h3>
                  <p className="text-white lg:text-xs xl:text-sm font-text">
                    Our walk-in queries or enrolment is currently done via
                    appointment. Please contact us to make an appointment.
                  </p>
                </div>
              </div>
            </div>
            {/* MAIN MENU */}
            <div className="bg-[#f2f2f2]">
              <div className="flex items-center justify-between px-4 py-3 text-center xl:mx-10 lg:mx-2">
                <NavLink
                  className="mx-3 text-sm font-semibold text-gray-600 uppercase"
                  to="/"
                >
                  Home
                </NavLink>
                <div class="dropdown dropdown-hover">
                  <NavLink
                    tabindex="0"
                    className="mx-3 text-sm font-semibold text-gray-600 uppercase"
                    to="#"
                  >
                    About
                  </NavLink>
                  <ul
                    tabindex="0"
                    className="text-sm bg-white drop-shadow-md dropdown-content menu w-52"
                  >
                    <li>
                      <NavLink to="/about">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/media">Media Releases</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="dropdown dropdown-hover">
                  <NavLink
                    tabindex="0"
                    className="mx-3 text-sm font-semibold text-gray-600 uppercase"
                    to="#"
                  >
                    Our Faculty
                  </NavLink>
                  <ul
                    tabindex="0"
                    className="text-sm bg-white drop-shadow-md dropdown-content menu w-60"
                  >
                    <li>
                      <NavLink to="/master-trainer">
                        Darren Tay- Master Trainer
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/senior-trainers-trainers">
                        Senior Trainers
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="dropdown dropdown-hover">
                  <NavLink
                    tabindex="0"
                    className="mx-3 text-sm font-semibold text-gray-600 uppercase"
                    to="/courses-and-programs"
                  >
                    Courses & Programs
                  </NavLink>
                  <ul
                    tabindex="0"
                    className="text-sm bg-white w-52 drop-shadow-md dropdown-content menu text-left"
                  >
                    <li>
                      <Link tabindex="1">Public Speaking for Kids</Link>
                      <ul
                        tabindex="1"
                        className="text-sm bg-white drop-shadow-md dropdown-content menu w-60"
                      >
                        <li>
                          <NavLink to="/group-classes-students-age-7-18">
                            Regular, Weekly Classes
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/school-holiday-public-speaking-program-for-children">
                            Holiday Camps / Masterclasses
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/interview-skills" className="block">
                            <p>DSA Interview Training Bootcamp</p>
                            <small className="italic">(*March Only)</small>
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link tabindex="1">Public Speaking for Adults</Link>
                      <ul
                        tabindex="1"
                        className="text-sm bg-white drop-shadow-md dropdown-content menu w-72"
                      >
                        <li>
                          <NavLink to="/courses-and-programs/group-classes-for-adults">
                            Regular, Weekly Classes
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/presentation-skills-training-course-by-world-champion">
                            Intensive Masterclasses/Bootcamps
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to="/courses-and-programs/intensive-holiday-ad-hoc-public-speaking-programs">
                        Intensive, Holiday and Ad-hoc Programs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/courses-and-programs/premium-1-to-1-public-speaking-coaching">
                        Premium 1-to-1
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="dropdown dropdown-hover">
                  <Link
                    tabindex="0"
                    className="mx-3 text-sm font-semibold text-gray-600 uppercase"
                    to="#"
                  >
                    Corporate/School Training
                  </Link>
                  <ul
                    tabindex="0"
                    className="text-sm bg-white drop-shadow-md dropdown-content menu w-60"
                  >
                    <li>
                      <NavLink to="/corporate-public-speaking-training-workshop">
                        Corporate/Business Training
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/public-speaking-training-for-schools-workshop">
                        School/Enrichment Programmes
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <NavLink
                  className="mx-3 text-sm font-semibold text-gray-600 uppercase"
                  to="/national-public-speaking-competition"
                >
                  Competitions
                </NavLink>
                <NavLink
                  className="mx-3 text-sm font-semibold text-gray-600 uppercase"
                  to="/category/blog"
                >
                  Blog
                </NavLink>
                <NavLink
                  className="mx-3 text-sm font-semibold text-gray-600 uppercase"
                  to="/contact"
                >
                  Contact
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
